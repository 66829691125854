<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="KYC Report" icon="chart-line" />

    <div class="generalBorderlessBox">
        <div class="row">
            <div class="col-12 mb-4">
                <!-- <div class="d-flex flex-wrap justify-content-between"> -->
                <div class="d-flex flex-wrap  justify-content-between">
                    <!-- <span class="filter-pill-border border">
                        <Popper class="popperDark" arrow hover content="Back to KYC Verification">
                            <fa icon="angle-left" size="lg" class="me-3 isLink" @click="$router.push({ path: '/backoffice/kyc' })" />
                        </Popper>
                    </span> -->

                    <span class="filter-pill-border border-0 pe-3 isLink" @click="$router.push({ path: '/backoffice/kyc' })">
                        <Popper class="popperDark" arrow hover content="Back to KYC Verification">
                            <fa icon="angle-left" size="lg"/>
                        </Popper>
                    </span>

                    <span class="filter-pill-border me-3">
                        <span class="filter-item-pill" :class="filterType === 'day' ? 'active' : ''" @click="filterType = 'day'">DAY</span>
                        <div class="vr"></div>
                        <span class="filter-item-pill" :class="filterType === 'month' ? 'active' : ''" @click="filterType = 'month'">MONTH</span>
                        <div class="vr"></div>
                        <span class="filter-item-pill" :class="filterType === 'year' ? 'active' : ''" @click="filterType = 'year'">YEAR</span>
                    </span>

                    <span v-if="filterType === 'day'" class="mb-2">
                        <div class="row p-0">
                            <div class="col-2 text-end pt-1">From</div>
                            <div class="col-10">
                                <VueDatePicker v-model="dateFr" model-type="yyyy-MM-dd" format="yyyy-MM-dd" :enable-time-picker="false" auto-apply></VueDatePicker>
                            </div>
                        </div>
                    </span>
                    
                    <span v-if="filterType === 'day'" class="mb-2">
                        <div class="row p-0">
                            <div class="col-2 text-end pt-1">To</div>
                            <div class="col-10">
                                <VueDatePicker v-model="dateTo" model-type="yyyy-MM-dd" format="yyyy-MM-dd" :enable-time-picker="false" auto-apply></VueDatePicker>
                            </div>
                        </div>
                    </span>
                    
                    <span v-if="filterType === 'month'" class="mb-2" style="width: 320px">
                        <div class="row p-0">
                            <div class="col-2 pt-1 text-end">From</div>
                            <div class="col-10">
                                <select v-model="monFr" class="form-select">
                                    <option :value="m" v-for="m in arrMonthYear" :key="m">{{ m }}</option>
                                </select>
                            </div>
                        </div>
                    </span>

                    <span v-if="filterType === 'month'" class="mb-2" style="width: 320px">
                        <div class="row p-0">
                            <div class="col-2 text-end pt-1">To</div>
                            <div class="col-10">
                                <select v-model="monTo" class="form-select">
                                    <option :value="m" v-for="m in arrMonthYear" :key="m">{{ m }}</option>
                                </select>
                            </div>
                        </div>
                    </span>

                    <span v-if="filterType === 'year'" class="mb-2" style="width: 320px">
                        <div class="row p-0">
                            <div class="col-2  text-end pt-1">From</div>
                            <div class="col-10">
                                <select v-model="yearFr" class="form-select">
                                    <option :value="y" v-for="y in arrYear" :key="y">{{ y }}</option>
                                </select>
                            </div>
                        </div>
                    </span>

                    <span v-if="filterType === 'year'" class="mb-2" style="width: 320px">
                        <div class="row p-0">
                            <div class="col-2  text-end pt-1">To</div>
                            <div class="col-10">
                                <select v-model="yearTo" class="form-select">
                                    <option :value="y" v-for="y in arrYear" :key="y">{{ y }}</option>
                                </select>
                            </div>
                        </div>
                    </span>

                    <!-- Large screen -->
                    <span class="filter-pill-border d-none d-md-block">
                        <span class="filter-item-pill" :class="filterStatus === '' ? 'active' : ''" @click="filterStatus = ''">All</span>
                        <div class="vr"></div>
                        <span class="filter-item-pill" :class="filterStatus === 'PENDING' ? 'active' : ''" @click="filterStatus = 'PENDING'">PENDING</span>
                        <div class="vr"></div>
                        <span class="filter-item-pill" :class="filterStatus === 'PROCESS' ? 'active' : ''" @click="filterStatus = 'PROCESS'">PROCESS</span>
                        <div class="vr"></div>
                        <span class="filter-item-pill" :class="filterStatus === 'VERIFIED' ? 'active' : ''" @click="filterStatus = 'VERIFIED'">VERIFIED</span>
                        <div class="vr"></div>
                        <span class="filter-item-pill" :class="filterStatus === 'REJECTED' ? 'active' : ''" @click="filterStatus = 'REJECTED'">REJECTED</span>
                    </span>

                    <!-- Small screen -->
                    <span class="filter-pill-border d-md-none">
                        <span class="filter-item-pill" :class="filterStatus === '' ? 'active' : ''" @click="filterStatus = ''">All</span>
                        <div class="vr"></div>
                        <span class="filter-item-pill" :class="filterStatus === 'PENDING' ? 'active' : ''" @click="filterStatus = 'PENDING'"><fa icon="user-clock" /></span>
                        <div class="vr"></div>
                        <span class="filter-item-pill" :class="filterStatus === 'PROCESS' ? 'active' : ''" @click="filterStatus = 'PROCESS'"><fa icon="user-gear" /></span>
                        <div class="vr"></div>
                        <span class="filter-item-pill" :class="filterStatus === 'VERIFIED' ? 'active' : ''" @click="filterStatus = 'VERIFIED'"><fa icon="user-shield" /></span>
                        <div class="vr"></div>
                        <span class="filter-item-pill" :class="filterStatus === 'REJECTED' ? 'active' : ''" @click="filterStatus = 'REJECTED'"><fa icon="user-minus" /></span>
                    </span>

                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-8">
                <div class="rptBox">
                    <ChartLine :data="statusData" :label="statusLabel" :key="diaKey" />
                </div>
            </div>

            <div v-if="filterStatus === ''" class="col-lg-4">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th>{{filterType.charAt(0).toUpperCase() + filterType.slice(1)}}</th>
                            <th class="text-end">Pending</th>
                            <th class="text-end">Process</th>
                            <th class="text-end">Verified</th>
                            <th class="text-end">Rejected</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(lbl, idx) in statusLabel" :key="lbl">
                            <td>{{statusLabel[idx]}}</td>
                            <td class="text-end">
                                <span class="text-primary isLink" @click="router.push({ path: '/backoffice/kyc', query: { status: 'PENDING', fr: statusLabel[idx], to: statusLabel[idx] } })">{{statusData[0].data[idx]}}</span>
                            </td>
                            <td class="text-end">
                                <span class="text-primary isLink" @click="router.push({ path: '/backoffice/kyc', query: { status: 'PROCESS', fr: statusLabel[idx], to: statusLabel[idx] } })">{{statusData[1].data[idx]}}</span>
                            </td>
                            <td class="text-end">
                                <span class="text-primary isLink" @click="router.push({ path: '/backoffice/kyc', query: { status: 'VERIFIED', fr: statusLabel[idx], to: statusLabel[idx] } })">{{statusData[2].data[idx]}}</span>
                            </td>
                            <td class="text-end">
                                <span class="text-primary isLink" @click="router.push({ path: '/backoffice/kyc', query: { status: 'REJECTED', fr: statusLabel[idx], to: statusLabel[idx] } })">{{statusData[3].data[idx]}}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>

            <div v-if="filterStatus !== ''" class="col-lg-4">
                <!-- router.push({ path: '/backoffice/kyc', query: { status: filterStatus, dateFr: '2022-09-10', dateTo: '2022-09-15' } }) -->
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th>{{filterType.charAt(0).toUpperCase() + filterType.slice(1)}}</th>
                            <th class="text-end">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(lbl, idx) in statusLabel" :key="lbl">
                            <td>{{statusLabel[idx]}}</td>
                            <td class="text-end">
                                <span class="text-primary isLink" @click="router.push({ path: '/backoffice/kyc', query: { status: filterStatus, fr: statusLabel[idx], to: statusLabel[idx] } })">{{statusData[0].data[idx]}}</span>
                                <!-- <span v-if="filterType === 'day'" class="isLink" @click="router.push({ path: '/backoffice/kyc', query: { status: filterStatus, fr: statusLabel[idx], to: statusLabel[idx] } })">{{statusData[0].data[idx]}}</span> -->
                                <!-- <span v-if="filterType === 'month'" class="isLink" @click="router.push({ path: '/backoffice/kyc', query: { status: filterStatus, fr: statusLabel[idx], to: statusLabel[idx] } })">{{statusData[0].data[idx]}}</span> -->
                                <!-- <span v-if="filterType === 'year'" class="isLink" @click="router.push({ path: '/backoffice/kyc', query: { status: filterStatus, fr: statusLabel[idx], to: statusLabel[idx] } })">{{statusData[0].data[idx]}}</span> -->
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

</template>

<script>
import { ref, inject, onMounted, watch } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { useRouter, useRoute } from 'vue-router'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import { useStore } from '@/stores/store'

import ChartLine from '@/components/chart/ChartLine.vue'
import ReportFigureBox from '@/components/ReportFigureBox.vue'

import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

export default {
    name: 'BOKycReport',
    components: { Alert, TopNavigation, Popper, ChartLine, ReportFigureBox, VueDatePicker },
    setup () {
        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const store = useStore()

        const filterType = ref('day')   // day, month, year
        const filterStatus = ref('')    // '', PENDING, PROCESS, VERIFIED, REJECTED

        const dateFr = ref(new Date())
        const dateTo = ref(new Date())
        const monFr = ref(null)
        const monTo = ref(null)
        const yearFr = ref(null)
        const yearTo = ref(null)
        const arrMonthYear = ref([])
        const arrYear = ref([])

        const diaKey = ref('dia')
        const statusData = ref([])
        const statusLabel = ref([])

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        watch([filterType, dateFr, dateTo, monFr, monTo, yearFr, yearTo, filterStatus], () => {
            // console.info('WATCH', filterType.value, dateFr.value, dateTo.value, monFr.value, monTo.value, yearFr.value, yearTo.value, filterStatus.value)
            // console.info('WATCH', 'filterStatus', filterStatus.value)

            diaKey.value = 'status' + Math.random() // reload the diagram purpose

            if (filterType.value === 'month') {
                // statusLabel.value = [ 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug']
                statusLabel.value = [ '2022-03', '2022-04', '2022-05', '2022-06', '2022-07', '2022-08']

            } else if (filterType.value === 'year') {
                statusLabel.value = [ '2017', '2018', '2019', '2020', '2021', '2022']

            } else {
                statusLabel.value = [ '2022-09-09', '2022-09-10', '2022-09-11', '2022-09-12', '2022-09-13', '2022-09-14']
            }

            if (filterStatus.value === '') {
                statusData.value = [
                    {
                        label: 'PENDING',
                        data: [65, 59, 80, 81, 86, 55, 90],
                        fill: false,
                        backgroundColor: "#77CEFF",
                        borderColor: "#77CEFF",
                        tension: 0.4,
                    },
                    {
                        label: "PROCESS",
                        data: [28, 48, 40, 19, 56, 27, 40],
                        fill: false,
                        backgroundColor: '#0079AF',
                        borderColor: '#0079AF',
                        tension: 0.4,
                    },
                    {
                        label: "VERIFIED",
                        data: [20, 35, 40, 50, 81, 35, 45],
                        fill: false,
                        backgroundColor: "#123E6B",
                        borderColor: "#123E6B",
                        tension: 0.4,
                    },
                    {
                        label: "REJECTED",
                        data: [20, 25, 30, 15, 86, 50, 40],
                        fill: false,
                        backgroundColor: "#97B0C4",
                        borderColor: "#97B0C4",
                        tension: 0.4,
                    },
                ]
            }

            if (filterStatus.value === 'PENDING') {
                // console.info('----', filterStatus.value, 'PENDING')
                statusData.value = [
                    {
                        label: 'PENDING',
                        data: [65, 59, 80, 81, 86, 55, 90],
                        fill: false,
                        backgroundColor: "#77CEFF",
                        borderColor: "#77CEFF",
                        tension: 0.4,
                    },
                ]
            }

            if (filterStatus.value === 'PROCESS') {
                statusData.value = [
                    {
                        label: "PROCESS",
                        data: [28, 48, 40, 19, 56, 27, 40],
                        fill: false,
                        backgroundColor: '#0079AF',
                        borderColor: '#0079AF',
                        tension: 0.4,
                    },
                ]
            }

            if (filterStatus.value === 'VERIFIED') {
                statusData.value = [
                    {
                        label: "VERIFIED",
                        data: [20, 35, 40, 50, 81, 35, 45],
                        fill: false,
                        backgroundColor: "#123E6B",
                        borderColor: "#123E6B",
                        tension: 0.4,
                    },
                ]
            }

            if (filterStatus.value === 'REJECTED') {
                statusData.value = [
                    {
                        label: "REJECTED",
                        data: [20, 25, 30, 15, 86, 50, 40],
                        fill: false,
                        backgroundColor: "#97B0C4",
                        borderColor: "#97B0C4",
                        tension: 0.4,
                    },
                ]
            }
        })

        onMounted(() => {

            let dateObj = new Date()
            let month = dateObj.getUTCMonth() + 1
            let day = dateObj.getUTCDate()
            let year = dateObj.getUTCFullYear()

            monFr.value = year + '-' + (month.toString().length === 1 ? '0' + month : month)
            monTo.value = year + '-' + (month.toString().length === 1 ? '0' + month : month)

            yearFr.value = year
            yearTo.value = year

            for (var y = year-1; y <= year; y++) {
                for (var m = 1; m <= 12; m++) {
                    if ( m.toString().length === 1) {
                        arrMonthYear.value.push(year + '-0' + m)
                    } else {
                        arrMonthYear.value.push(year + '-' + m)
                    }
                }
                // arrMonthYear.value.push(y)
            }

            for (var y = year-10; y <= year; y++) {
                arrYear.value.push(y)
            }

            statusLabel.value = [ '2022-09-09', '2022-09-10', '2022-09-11', '2022-09-12', '2022-09-13', '2022-09-14']

            // '#77CEFF', '#0079AF', '#123E6B', '#97B0C4', '#A5C8ED'
            statusData.value = [
                {
                    label: 'PENDING',
                    data: [65, 59, 80, 81, 86, 55, 90],
                    fill: false,
                    backgroundColor: "#77CEFF",
                    borderColor: "#77CEFF",
                    tension: 0.4,
                },
                {
                    label: "PROCESS",
                    data: [28, 48, 40, 19, 56, 27, 40],
                    fill: false,
                    backgroundColor: '#0079AF',
                    borderColor: '#0079AF',
                    tension: 0.4,
                },
                {
                    label: "VERIFIED",
                    data: [20, 35, 40, 50, 81, 35, 45],
                    fill: false,
                    backgroundColor: "#123E6B",
                    borderColor: "#123E6B",
                    tension: 0.4,
                },
                {
                    label: "REJECTED",
                    data: [20, 25, 30, 15, 86, 50, 40],
                    fill: false,
                    backgroundColor: "#97B0C4",
                    borderColor: "#97B0C4",
                    tension: 0.4,
                },
            ]
            
        })

        return { 
            alert, closeAlert, router, func, store, 
            filterType, filterStatus, statusData, statusLabel, dateFr, dateTo, monFr, monTo, yearFr, yearTo, 
            arrMonthYear, arrYear, diaKey,
        }
    }
}
</script>
// npm install @vuepic/vue-datepicker
// https://vue3datepicker.com/installation/

<style>
.rptBox {
    border-radius: 10px;
    padding: 25px;
    margin-bottom: 25px;
    margin-left: 2px;
    margin-right: 2px;
    background-color: var(--chart-bg);
}

.filter-pill-border {
    border: 1px solid var(--accent-color);
    border-radius: 5px; 
    font-size: 14px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-bottom: 10px;
}

.filter-item-pill {
    cursor: pointer;
    padding-left: 15px;
    padding-right: 15px;
}

.filter-item-pill:hover {
    color: var(--bs-primary);
}

.filter-item-pill:active, .filter-item-pill.active {
    color: var(--bgcolor-body-color);
    background-color: #53BDEB;
    border-radius: 5px;
    padding: 9px 15px;
    cursor: default;
    margin: 0px;
}

.filter-item-pill:disabled, .filter-item-pill.disabled {
    color: var(--toolbar-hr);
    border-radius: 5px;
    padding: 9px 15px;
    cursor: default;
    margin: 0px;
}

.dp__input {
    font-size: 14px;
}
</style>